exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-comics-index-js": () => import("./../../../src/pages/comics/index.js" /* webpackChunkName: "component---src-pages-comics-index-js" */),
  "component---src-pages-comics-mdx-slug-js": () => import("./../../../src/pages/comics/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-comics-mdx-slug-js" */),
  "component---src-pages-commission-js": () => import("./../../../src/pages/commission.js" /* webpackChunkName: "component---src-pages-commission-js" */),
  "component---src-pages-illustration-js": () => import("./../../../src/pages/illustration.js" /* webpackChunkName: "component---src-pages-illustration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ink-js": () => import("./../../../src/pages/ink.js" /* webpackChunkName: "component---src-pages-ink-js" */),
  "component---src-pages-work-mdx-slug-js": () => import("./../../../src/pages/work/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-work-mdx-slug-js" */)
}

